<template>
	<div class="main-content">
		<!--BreadCrumbs-->
		<ktv-breadcrumb folder="Premium Points" page="Agri Input Request" title="Agri Input Request List" />
		<!--Tables Grid-->
		<TablesGrid />
	</div>
</template>
<script>
	import { KtvBreadcrumb } from "@/components"
	import TablesGrid from "./tables/TablesGrid"

	export default {
		metaInfo: {
			title: "Agri Input Request",
		},
		components: {
			KtvBreadcrumb,
			TablesGrid,
		},
	}
</script>
