<template>
	<div>
		<ktv-table
			mode="remote"
			:search-placeholder="$t('Searching by ID', 'Searching by ID')"
			:fixed-header="true"
			:is-loading="loading"
			:columns="columns"
			:rows="rows"
			:total-rows="totalRecords"
			:rows-per-page="serverParams.limit"
			:search-enabled="false"
			@on-open-filter="openFilter()"
			@on-page-change="onPageChange"
			@on-sort-change="onSortChange"
			@on-per-page-change="onPerPageChange"
			@on-search="onSearchFilter"
		>
			<template #columns="{ props }">
				{{ $t(props.column.label, props.column.label) }}
			</template>
			<template #rows="{ props }">
				<span v-if="props.column.field === 'button'">
					<b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
						<template #button-content>
							<span><i class="nav-icon i-Arrow-Down-in-Circle" /></span>
						</template>
						<b-dropdown-item href="#" @click="onDetail(props.row.premiumDisburseId)">
							<a v-b-tooltip.hover href="#" title="Edit">
								<i class="i-Pen-3 text-18 text-success mr-2" />
								{{ $t("Detail", "Detail") }}
							</a>
						</b-dropdown-item>
						<b-dropdown-item href="#">
							<i class="i-Download text-18 text-success mr-2" />
							{{ $t("Download", "Download") }}
						</b-dropdown-item>
					</b-dropdown>
				</span>
				<span v-else-if="props.column.field === 'totalPremiumAmount'">
					{{ props.row.totalPremiumAmount >= 0 ? IDRFilter(Math.round(props.row.totalPremiumAmount)) : "-" }}
				</span>
			</template>
		</ktv-table>

		<!--modal filtering-->
		<ktv-modal v-model="modalsFilter" title="Filter" @close="modalsFilter = false">
			<template #content>
				<ktv-select v-model="filter.status" :options="StatusOptions" :label="$t('Status', 'Status')" />
				<ktv-select v-model="filter.site" :options="SiteOptions" :label="$t('Site', 'Site')" />
				<ktv-select v-model="filter.hubarea" :options="HubOptions" :label="$t('Hub Area', 'Hub Area')" />
				<ktv-input v-model="filter.date" type="date" label="Date" />

				<b-form-group class="col-md-12 mb-2">
					<div style="margin-top: 10px" />
					<b-button variant="primary" size="sm" class="float-right" @click="onAdvancedSearch"> {{ $t("Apply Filter", "Apply Filter") }} </b-button>
					<b-button variant="light" size="sm" class="float-right" style="margin-right: 5px" @click="modalsFilter = false">
						{{ $t("Close", "Close") }}
					</b-button>
				</b-form-group>
			</template>
		</ktv-modal>
	</div>
</template>
<script>
	const column = [
		{
			label: "Action",
			field: "button",
			html: true,
			tdClass: "text-center",
			thClass: "text-center",
			sortable: false,
		},
		{
			label: "ID",
			field: "premiumDisburseId",
			thClass: "text-left",
			width: "150px",
		},
		{
			label: "Status",
			field: "statusDesc",
			thClass: "text-left",
			width: "150px",
		},
		{
			label: "Site",
			field: "siteName",
			thClass: "text-left",
			width: "200px",
		},
		{
			label: "Hub Area",
			field: "areaCode",
			thClass: "text-left",
			width: "150px",
		},
		{
			label: "Start Date",
			field: "premiumStartDate",
			thClass: "text-left",
			width: "150px",
		},
		{
			label: "End Date",
			field: "premiumEndDate",
			thClass: "text-left",
			width: "150px",
		},
		{
			label: "CVC Association",
			field: "cvcAssociation",
			thClass: "text-left",
			width: "250px",
		},
		{
			label: "Premium Points",
			field: "totalPremiumAmount",
			thClass: "text-left",
			width: "150px",
		},
		{
			label: "Created At",
			field: "createdDate",
			thClass: "text-left",
			width: "150px",
		},
	]

	import { KtvTable, KtvModal, KtvSelect, KtvInput } from "@/components"
	import { mapActions, mapGetters, mapState } from "vuex"
	import { map } from "underscore"
	export default {
		components: {
			KtvTable,
			KtvModal,
			KtvSelect,
			KtvInput,
		},
		data() {
			return {
				modalsFilter: false,
				loading: false,
				/* KtvTable property */
				totalRecords: 0,
				columns: column,
				rows: [],
				serverParams: {
					search: null,
					order: null,
					page: 1,
					limit: 10,
				},
				sortKey: "",
				StatusOptions: [],
				SiteOptions: [],
				HubOptions: [],
				filter: {
					status: null,
					site: null,
					hubarea: null,
					date: null,
				},
				/* End KtvTable property */
			}
		},
		computed: {
			...mapGetters({
				loggedInUser: "AUTHDATA/loggedInUser",
				getAgriinputRequest: "AGRIINPUTREQUEST/getAgriinputRequest",
			}),
			...mapState("OTHERS", ["hubAreaList", "unitList", "statusPremiumList"]),
		},
		watch: {
			hubAreaList: {
				deep: true,
				handler() {
					this.gethubAreaListOptions()
				},
				immediate: true,
			},
			unitList: {
				deep: true,
				handler() {
					this.getunitListOptions()
				},
				immediate: true,
			},
			statusPremiumList: {
				deep: true,
				handler() {
					this.getstatusPremiumListOptions()
				},
				immediate: true,
			},
		},
		created() {
			this.loadItems()
			this.getHubAreaList()
			this.getUnitList()
			this.getstatusPremiumList("pt_premium")
		},
		methods: {
			...mapActions({
				ActionTablesGrid: "AGRIINPUTREQUEST/ActionTablesGrid",
				getHubAreaList: "OTHERS/getHubAreaList",
				getUnitList: "OTHERS/getUnitList",
				getstatusPremiumList: "OTHERS/getstatusPremiumList",
			}),
			IDRFilter(amount) {
				return this.$options.filters.Rupiah(amount)
			},
			onDetail(premiumDisburseId) {
				this.$router.push("/agriinputrequest/detail/" + premiumDisburseId)
			},
			openFilter() {
				this.modalsFilter = true
			},
			/**
			 * KtvTables Methods
			 */
			updateParams(newProps) {
				this.serverParams = Object.assign({}, this.serverParams, newProps)
			},
			// Untuk Paging Next - Previous hit
			onPageChange(params) {
				this.updateParams({ page: params.currentPage })
				this.loadItems()
			},
			// Untuk melakukan Jumlah dropdown Rowsperpages
			onPerPageChange(params) {
				this.updateParams({ limit: params.currentPerPage })
				this.loadItems()
			},
			// Untuk melakukan Sorting data
			onSortChange(params) {
				this.sortKey = params[0].field
				this.columns[params[0].field] = !this.columns[params[0].field]
				let oParams = {
					namaField: params[0].field,
					orderBy: this.columns[params[0].field] === false ? "asc" : "desc",
				}
				const NewParams = new URLSearchParams(oParams).toString()
				this.serverParams.order = NewParams
				this.loadItems()
			},
			// Untuk Load Data
			loadItems() {
				this.loading = true
				this.ActionTablesGrid(this.serverParams).then((resp) => {
					this.loading = false
					this.totalRecords = resp.total
					if (resp) this.rows = resp.data
				})
			},
			onSearchFilter(params) {
				const request = {
					id: params === null || params === "" ? "" : params,
				}
				Object.keys(request).forEach((k) => !request[k] && request[k] !== undefined && delete request[k])
				const NewParams = new URLSearchParams(request).toString()
				this.serverParams.search = decodeURIComponent(NewParams.replace(/\+/g, "%20"))
				this.loadItems()
			},
			onAdvancedSearch() {
				this.modalsFilter = false
				const request = {
					status: this.filter.status === null || this.filter.status === "" ? "" : this.filter.status,
					siteName: this.filter.site === null || this.filter.site === "" ? "" : this.filter.site,
					areaCode: this.filter.hubarea === null || this.filter.hubarea === "" ? "" : this.filter.hubarea,
					date: this.filter.date === null ? "" : this.filter.date,
				}
				Object.keys(request).forEach((k) => !request[k] && request[k] !== undefined && delete request[k])
				const NewParams = new URLSearchParams(request).toString()
				this.serverParams.search = decodeURIComponent(NewParams.replace(/\+/g, "%20"))
				this.loadItems()
			},
			/**
			 * End KtvTables Methods
			 */

			gethubAreaListOptions() {
				this.HubOptions = map(this.hubAreaList, (el) => {
					return {
						text: el.areaCode,
						value: el.areaCode,
					}
				})
			},
			getunitListOptions() {
				this.SiteOptions = map(this.unitList, (el) => {
					return {
						text: el.unit,
						value: el.unitId,
					}
				})
			},
			getstatusPremiumListOptions() {
				this.StatusOptions = map(this.statusPremiumList, (el) => {
					return {
						text: el.value,
						value: el.listOfValueId,
					}
				})
			},
		},
	}
</script>
